import translations from '../../../utils/translations'
import * as _ from 'lodash'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { createGlobalDesignManifest, DESIGN_GROUPS } from './global-design-manifest'
import { COMPONENT_TYPES } from '../preset/fields/component-types'

const getDisplayName = (plugins: FormPlugin[] = []) => {
  if (plugins.includes(FormPlugin.REGISTRATION_FORM)) {
    return 'registrationFormBuilder'
  } else if (isGetSubscribers(plugins)) {
    return 'getSubscribersFormBuilder'
  }

  return 'formBuilder'
}

const isGetSubscribers = (plugins: FormPlugin[] = []) =>
  plugins.includes(FormPlugin.GET_SUBSCRIBERS)

const isPluginlessForm = (plugins: FormPlugin[] = []) =>
  plugins.length === 0 || (plugins.length === 1 && plugins.includes(FormPlugin.FORM_BUILDER))

const getHelpId = (plugins: FormPlugin[] = []) => {
  return plugins.includes(FormPlugin.REGISTRATION_FORM)
    ? GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER
    : GFPP.HELP_ID.FORM_CONTAINER
}

const getGfppActions = ({ plugins, showNewSettingsPanel, extraIconButtons }) => {
  let mainAction1, mainAction2

  const shouldShowNewSettingsPanelAsMainAction = showNewSettingsPanel && isPluginlessForm(plugins)

  const iconButtons = {
    [GFPP_ACTIONS.LAYOUT]: {
      actionId: GFPP_IDS.FORM_LAYOUT,
    },
    [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
    [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
    [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
    ...extraIconButtons,
  }

  if (shouldShowNewSettingsPanelAsMainAction) {
    mainAction1 = {
      actionId: GFPP_IDS.NEW_FORM_SETTINGS,
      label: translations.t('formSettings.gfppTitle'),
    }
    mainAction2 = {
      actionId: GFPP_IDS.MANAGE_FIELDS,
      label: translations.t('manageFields.gfppTitle'),
    }
  } else {
    iconButtons[GFPP_ACTIONS.SETTINGS] = {
      actionId: GFPP_IDS.FORM_SETTINGS,
    }
    mainAction1 = {
      actionId: GFPP_IDS.MANAGE_FIELDS,
      label: translations.t('manageFields.gfppTitle'),
    }

    if (isGetSubscribers(plugins)) {
      mainAction2 = {
        actionId: GFPP_IDS.MANAGE_SUBSCRIBERS,
        label: translations.t('manageSubscribers.gfppTitle'),
      }
    } else {
      mainAction2 = GFPP.REMOVE
    }
  }

  return { mainAction1, mainAction2, iconButtons }
}

export const createBoxManifest = ({
  isTopPremium,
  plugins,
  duplicatable = false,
  showNewSettingsPanel = false,
}: AppStateObject = {}) => {
  const displayName = getDisplayName(plugins)
  const extraIconButtons = {
    ...(isTopPremium ? {} : { [GFPP_ACTIONS.UPGRADE]: GFPP.KEEP_DEFAULT }),
  }
  const gloablDesignManifest = {
    gfpp: {
      desktop: {
        globalDesign: {
          ...createGlobalDesignManifest(),
        },
      },
    },
    designMapping: {
      [COMPONENT_TYPES.FORM_CONTAINER]: { [DESIGN_GROUPS.FORM_BACKGROUND]: ['bg'] },
    },
  }

  const { mainAction1, mainAction2, iconButtons } = getGfppActions({
    plugins,
    showNewSettingsPanel,
    extraIconButtons,
  })

  const manifest = {
    showFocusBox: true,
    displayName: translations.t(displayName),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        mainAction1,
        mainAction2,
        iconButtons,
        helpId: getHelpId(plugins),
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }
  return _.merge(manifest, gloablDesignManifest)
}
